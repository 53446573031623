import React from "react";
import Main from "../Home/sections/Main";

const Home = () => {
  return (
    <>
      <Main />
    </>
  );
};

export default Home;
