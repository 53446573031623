import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

function Event({ content, date, images }) {
    const settings = {
        dots: false,
        fade: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        waitForAnimate: false
    };

    return (
        <div className="event">
            <div className="slider-container">
                <Slider {...settings}>
                    {Object.entries(images).map(([key, image]) => (
                        <div key={key}>
                            <img src={image.original_url} alt={`slider-img-${key}`} loading="lazy"/>
                        </div>
                    ))}
                </Slider>
            </div>
            <div className="detail">
                <p className="date">{date}</p>
                <p className="description">
                    {content}
                </p>
            </div>
        </div>)
}

export default Event