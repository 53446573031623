export const enTranslation = {
  translation: {
    header: {
      about: "About",
      services: "Services",
      legislation: "Legislation",
      certificates: "Certificates",
      faq: "FAQ",
      contact: "Contact",
      search: "Search here",
    },
    home: {
      content:
        "MINOR ВUSINESS GROUP LLC, which has been operating since 2017, provides business entities with many areas of law, as well as obtaining licenses...",
      moreBtn: "Detailed",
      contactBtn: "Contact us",
      title1: "Legal service in obtaining licenses!",
      title2: "Leave your rights to the professionals!",
      serviceNum: "Service number",
      customerNum: "Satisfied customer",
      collaboratorNum: "Collaborator",
    },
    about: {
      title2: "2 more companies operate under Minor Companies Group LLC:",
      title3: "Letters of recommendation from our partner companies:",
      title4: "Our events:",
      btn: "Detailed",
    },
    services: {
      title: "Services",
      backBtn: "Back",
      categories: "Categories",
      contactBtn: "Contact us",
      noResults: "No results were found for your search.",
    },
    certificates: {
      head: "Certificates",
      other: "Other certificates",
    },
    faq: {
      title: "Frequently asked questions",
      btn: "Contact us",
    },
    contact: {
      info: {
        title: "Contact us",
        addressName: "Address",
        address: "109 M. Araz Street, Atatürk Avenue, Baku, Azerbaijan",
        mailName: "E-mail",
        phoneName: "Tel",
        btn: "Contact us",
      },
      form: {
        backBtn: "Back",
        contactBtn: "Contact us",
        toast: "Please enter all information",
        placeholder: {
          name: "Name, Surname",
          number: "Contact number",
          select: "Service type",
        },
        sentBtn: "Sent",
        sendBtn: "Send",
        captchaError: "Please confirm you're not a robot",
      },
    },
  },
};
