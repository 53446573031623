import React from 'react'
import './spinner.scss'

function Spinner() {
    return (
        <div className="loading">
            <img
                width={100}
                height={100}
                src="/gif/loading2.gif"
            />
        </div>)
}

export default Spinner