import React, { useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import "./accordion.scss";

const Accordion = ({ title, content }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={`accordion-item ${isOpen ? "open" : ""}`}>
            <div className="accordion-title" onClick={toggleAccordion}>
                <h3>{title}</h3>
                <span>{isOpen ? <FaChevronUp /> : <FaChevronDown />}</span>
            </div>
            <div className={`accordion-content`}>
                <div className="accordion-content-inner">
                    {content}
                </div>
            </div>
        </div>
    );
};

export default Accordion;
