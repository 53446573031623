import React from 'react'
import { Image } from 'antd';

function Letter({ title, image }) {
    return (
        <div className="letter-box">
            <div className="letter">
                <Image src={image} alt="letter" />
            </div>
            <span>{title}</span>
        </div>
    )
}

export default Letter