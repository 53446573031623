import React from "react";
import "./services.scss";
import { Link, Outlet, useLocation, useOutletContext } from "react-router-dom";
import { useTranslation } from "react-i18next";
import long from '../../assets/images/png/long-arrow.png'
import bg from '../../assets/images/png/services-bg.jpg'
import { LangProvider } from "../../context/LangContext";

const Services = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const { inputValue, setInputValue } = useOutletContext();
  const isServicesSide = location.pathname === "/services";

  return (
    <LangProvider>
      <img
        className="services_bg"
        src={bg}
        alt="services background"
      />
      <div className="services">
        {!isServicesSide && (
          <Link to="/services" className="services_back_button">
            <img
              className=""
              width={41}
              height={41}
              src={long}
              alt="arrow icon"
            />
            {t("services.backBtn")}
          </Link>
        )}
        <Outlet context={{ inputValue, setInputValue }} />
      </div>
    </LangProvider>
  );
};

export default Services;
