import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import Loading from "../../../components/Loading";
import { Swiper, SwiperSlide } from "swiper/react";
import "../sections/Main.css";
import SliderMobile from "../../../assets/images/mobile-slider-img.png";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";
import Spinner from "../../../components/Spinner";
import { getApi } from "../../../api/customApi";
import { LangContext } from "../../../context/LangContext";

import "swiper/css";
import "swiper/css/pagination";

import { Mousewheel, Pagination } from "swiper/modules";

const Main = () => {
  const { lang } = useContext(LangContext);

  const { data: home, isLoading: homeItemsLoading } = useQuery({
    queryKey: ["home", lang],
    queryFn: () => getApi("/home"),
  });

  const { setActiveSlide } = useOutletContext();
  const { t } = useTranslation();

  return (
    <div>
      {homeItemsLoading && <Loading />}

      {!homeItemsLoading && (
        <Swiper
          direction={"vertical"}
          slidesPerView={1}
          spaceBetween={30}
          mousewheel={true}
          pagination={{
            clickable: true,
          }}
          modules={[Mousewheel, Pagination]}
          className="mySwiper"
          speed={1000}
          onSlideChange={(swiper) => {
            setActiveSlide(swiper.activeIndex);
          }}
        >
          <div className="container-header">
            <SwiperSlide className="swiper-content swiper-first-active">
              <div className="overlay-container">
                <img
                  src={
                    Object.entries(home?.slider1_image || {})?.[0]?.[1]
                      ?.original_url || ""
                  }
                  className="swiper-slide-img rotated-image desktop-image"
                  alt="sliderImage"
                  loading="lazy"
                />
                <img
                  src={SliderMobile}
                  className="rotated-image mobile-image"
                  alt=""
                  loading="lazy"
                />
                <div className="overlay"></div>
                <div className="slider-about">
                  <div className="text">
                    <h1>{home?.slider1_title?.[lang]}</h1>
                  </div>
                  <div className="slider-about-desc">
                    <p>{home?.slider1_desc?.[lang] || t("home.content")}</p>
                  </div>
                  <div className="slider-buttons">
                    <NavLink to={home?.slider1_button_url}>
                      {home?.slider1_button_text?.[lang] || t("home.moreBtn")}
                    </NavLink>
                    <NavLink
                      to={home?.slider1_button2_url}
                      className="slider-contact-button"
                    >
                      {home?.slider1_button2_text?.[lang] ||
                        t("home.contactBtn")}
                    </NavLink>
                  </div>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide className="swiper-content">
              <div className="overlay-container-light">
                <img
                  src={
                    Object.entries(home?.slider2_image || {})?.[0]?.[1]
                      ?.original_url || ""
                  }
                  className="swiper-slide-img"
                  alt="sliderImage"
                  loading="lazy"
                />
                <div className="overlay-light"></div>
                <div className="slider-about">
                  <div className="text-slider2">
                    <h2>{home?.slider2_title?.[lang] || t("home.title1")}</h2>
                  </div>
                  <div className="slider-buttons-slider2">
                    <NavLink to={home?.slider2_button_url}>
                      {home?.slider2_button_text?.[lang] || t("home.moreBtn")}
                    </NavLink>
                    <NavLink
                      to={home?.slider2_button2_url}
                      className="slider-contact-button"
                    >
                      {home?.slider2_button2_text?.[lang] ||
                        t("home.contactBtn")}
                    </NavLink>
                  </div>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide className="swiper-content">
              <div className="overlay-container-light">
                <img
                  src={
                    Object.entries(home?.slider3_image || {})?.[0]?.[1]
                      ?.original_url || ""
                  }
                  className="swiper-slide-img"
                  alt="sliderImage"
                  loading="lazy"
                />
                <div className="overlay-light"></div>
                <div className="slider-about">
                  <div className="text-slider2">
                    <h2>{home?.slider3_title?.[lang] || t("home.title2")}</h2>
                  </div>
                  {homeItemsLoading ? (
                    <Spinner />
                  ) : (
                    <div className="slider-counter">
                      <div className="counter-text">
                        <h3>{home?.slider3_countService}+</h3>
                        <span>{t("home.serviceNum")}</span>
                      </div>
                      <div className="counter-text">
                        <h3>{home?.slider3_countCustomer}+</h3>
                        <span>{t("home.customerNum")}</span>
                      </div>
                      <div className="counter-text">
                        <h3>{home?.slider3_countCollab}</h3>
                        <span>{t("home.collaboratorNum")}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </SwiperSlide>
          </div>
        </Swiper>
      )}
    </div>
  );
};

export default Main;
