import apiClient from ".";

export const getApi = async (url) => {
  try {
    const res = await apiClient.get(url);
    return res.data;
  } catch (err) {
    return err.message;
  }
};

export const postApi = (url, data) => {
  apiClient.post(url, data);
};
