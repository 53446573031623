import About from "../pages/About";
import Certification from "../pages/Certification";
import Certificates from "../pages/Certification/certificates";
import CertificateDetail from "../pages/Certification/detail";
import Contact from "../pages/Contact";
import ContactForm from "../pages/Contact/ContactForm";
import FAQ from "../pages/FAQ";
import Home from "../pages/Home";
import Prologue from "../pages/Prologue";
import Root from "../pages/Root";
import Services from "../pages/Services";
import ServiceDetail from "../pages/Services/detail";
import ServiceSide from "../pages/Services/serviceSide";

export const ROOT = [
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/prologue",
        element: <Prologue />,
      },
      {
        path: "/faq",
        element: <FAQ />,
      },
      {
        path: "/certification",
        element: <Certification />,
        children: [
          {
            path: "/certification",
            element: <Certificates />,
          },
          {
            path: "/certification/:slug",
            element: <CertificateDetail />,
          },
        ],
      },
      {
        path: "/contact",
        element: <Contact />,
      },
      {
        path: "/contact-form",
        element: <ContactForm />,
      },
      {
        path: "/services",
        element: <Services />,
        children: [
          {
            path: "/services",
            element: <ServiceSide />,
          },
          {
            path: "/services/:slug",
            element: <ServiceDetail />,
          },
        ],
      },
    ],
  },
];
