import React from "react";
import { useLocation } from "react-router-dom";
import "../Footer/style.css";
import { FaRegCopyright } from "react-icons/fa6";
import { IoLogoInstagram } from "react-icons/io5";
import { FaFacebookF } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { IoLogoWhatsapp } from "react-icons/io";
import { useMediaQuery } from "react-responsive";
import { useQuery } from "@tanstack/react-query";
import { getApi } from "../../api/customApi";

const Footer = ({ activeSlide }) => {
  const { data: configuration } = useQuery({
    queryKey: ["configuration"],
    queryFn: () => getApi("/configuration"),
  });

  const currentYear = new Date().getFullYear();

  const formatPhoneNumber = (phoneNumber) => {
    return phoneNumber?.replace(/[^\d]/g, "");
  };
  const formattedPhoneNumber = formatPhoneNumber(configuration?.tel || "(+994) 70 255 09 20");
  
  const location = useLocation();
  const isMainPage = location.pathname === "/";
  const isFirstSlide = activeSlide === 0;
  const isSecondSlide = activeSlide === 1;
  const footerBackgroundColor = isFirstSlide ? "black" : "white";
  const footerColor = isFirstSlide ? "white" : "black";
  const isMobile = useMediaQuery({ maxWidth: 992 });

  if (isMainPage && isMobile && (isFirstSlide || isSecondSlide)) {
    return (
      <div className="footer">
        <div
          className={`sticky-wp ${isMainPage ? "main-page" : "other-page"}`}
          style={{ bottom: "70px" }}
        >
          <NavLink
            to={`https://api.whatsapp.com/send?phone=${formattedPhoneNumber}`}
            target="blank"
          >
            <IoLogoWhatsapp />
          </NavLink>
        </div>
      </div>
    );
  }

  return (
    <div className="footer">
      <div
        className={`footer-wrapper ${
          isMainPage ? "fixed-footer" : "relative-footer"
        }`}
      >
        <div
          className={`footer-copyright ${
            isMainPage ? "main-page" : "other-page"
          }`}
          style={{ display: "flex", alignItems: "center" }}
        >
          <FaRegCopyright
            style={!isMainPage ? { color: "black" } : { color: footerColor }}
          />
          <span
            style={!isMainPage ? { color: "black" } : { color: footerColor }}
          >
            {currentYear}
          </span>
          <p style={!isMainPage ? { color: "black" } : { color: footerColor }}>
            Minor Business Group MMC
          </p>
        </div>
        <div
          className={`footer-socials ${
            isMainPage ? "main-page" : "other-page"
          }`}
          style={{
            display: "flex",
            alignItems: "center",
            backgroundColor: isMainPage ? footerBackgroundColor : "white",
          }}
        >
          <NavLink
            to={configuration?.insta}
            target="blank"
            style={!isMainPage ? { color: "black" } : { color: footerColor }}
          >
            <IoLogoInstagram />
          </NavLink>
          <NavLink
            to={configuration?.fb}
            target="blank"
            style={!isMainPage ? { color: "black" } : { color: footerColor }}
          >
            <FaFacebookF />
          </NavLink>
          <NavLink
            to={configuration?.linkedin}
            target="blank"
            style={!isMainPage ? { color: "black" } : { color: footerColor }}
          >
            <FaLinkedinIn />
          </NavLink>
        </div>
      </div>
      <div className={`sticky-wp ${isMainPage ? "main-page" : "other-page"}`}>
        <NavLink
          to={`https://api.whatsapp.com/send?phone=${formattedPhoneNumber}`}
          target="blank"
        >
          <IoLogoWhatsapp />
        </NavLink>
      </div>
    </div>
  );
};

export default Footer;
