import React, { useState } from "react";
import Header from "../components/Header/index.jsx";
import Footer from "../components/Footer/index.jsx";
import { Outlet } from "react-router-dom";
import { LangProvider } from "../context/LangContext";

const Root = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [inputValue, setInputValue] = useState("");

  return (
    <LangProvider>
      <Header activeSlide={activeSlide} inputValue={inputValue} setInputValue={setInputValue}/>
      <main>
        <Outlet context={{ setActiveSlide, inputValue, setInputValue }}/>
      </main>
      <Footer activeSlide={activeSlide}/>
    </LangProvider>
  );
};

export default Root;
