import React, { useEffect, useRef, useState } from "react";
import "./category.scss";
import { Link } from "react-router-dom";
import { LangContext } from "../../../context/LangContext";
import { useContext } from "react";

export const ServiceCategory = ({name, services, updateService, slug, activeService}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const { lang } = useContext(LangContext)

  const setService = (az, en, ru) => {
    localStorage.setItem("minor_service_name", JSON.stringify({az: az, en: en, ru: ru}));
    updateService()
  }

  const menuRef = useRef(null)

  const handleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleClickOutside = (e) => {
    if(menuRef.current && !menuRef.current.contains(e.target)) {
      setIsMenuOpen(false);
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.addEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div ref={menuRef} className="service_category">
      <div className={`service_category_name ${ (isMenuOpen || services?.some(service => service.title[lang] === activeService)) ? 'active_service_name' : '' }`} onClick={handleMenu}>
        {name}
      </div>
      <ul
        className={`service_category_container ${
          isMenuOpen ? "service_category_active_container" : ""
        }`}
      >
        {services && services.map((service, index) => (
          <li key={index} className="service_category_container_element">
            <Link
              to={`/services/${service?.service_slug}`}
              
              className={`service_side_container_link ${activeService === service.title[lang] ? 'active_service_name': ''}`}
              onClick={() => {
                setService(service.title?.az, service.title?.en, service.title?.ru)
              }}
            >
                {service.title[lang]}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};
