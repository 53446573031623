import React from 'react'
import { useTranslation } from "react-i18next";

function Company({ title, content, image, url }) {
    const { t } = useTranslation();

    return (
        <div className="company">
            <div className="logo-box">
                <img src={image} alt={title} loading="lazy" />
            </div>
            <p>{title}</p>
            <small>{content}</small>
            <div className="btn-box">
                <button
                    onClick={() => window.location.href = url}
                    className="btn">{t("about.btn")}</button>
            </div>
        </div>)
}

export default Company