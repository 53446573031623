import React, { useContext, useEffect } from "react";
import "./certificates.scss";
import Certificate from "../../../components/Certification/Certificate";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getApi } from "../../../api/customApi";
import Spinner from "../../../components/Spinner";
import { LangContext } from "../../../context/LangContext";
import { useTranslation } from "react-i18next";

const Certificates = () => {
  const { lang } = useContext(LangContext)
  const { t } = useTranslation();
  const { data: certificatesData, isLoading: certificatesIsLoading } = useQuery({
    queryKey: ["certificates", lang],
    queryFn: () => getApi("/certificates"),
  });

  useEffect(() => {
    certificatesData && console.log(certificatesData);
  }, [certificatesData]);

  const setCertificate = (content, name, description) => {
    localStorage.setItem("minor_certificate", JSON.stringify({content, name, description}))
  }
  return (
    <div className="certificates">
      <h1 className="certificates_head_label">{t("certificates.head")}</h1>
      <div className="certificates_container">
        {certificatesData &&
          certificatesData?.map((data) => (
            <Link
              to={`/certification/${data?.slug?.[lang]}`}
              className="certificates_container_link"
              onClick={() => setCertificate(data.content?.[lang], data.title?.[lang], data.description?.[lang])}
            >
              <Certificate code={data.title?.[lang]} label={data.description?.[lang]} />
            </Link>
          ))}
      </div>
        {certificatesIsLoading && <Spinner />}
    </div>
  );
};

export default Certificates;
