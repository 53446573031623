import React, { useContext, useEffect, useState } from "react";
import "./detail.scss";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getApi } from "../../../api/customApi";
import Spinner from "../../../components/Spinner";
import { LangContext } from "../../../context/LangContext";
import { t } from "i18next";
import { ServiceCategory } from "../../../components/Services/Category";

const ServiceDetail = () => {
  const { lang } = useContext(LangContext);
  const [serviceName, setServiceName] = useState(() => {
    const storedValue = localStorage.getItem("minor_service_name");
    return storedValue ? JSON.parse(storedValue)?.[lang] || null : null;
  });
  const navigate = useNavigate();

  useEffect(() => {
    const storedValue = localStorage.getItem("minor_service_name");
    setServiceName(
      storedValue ? JSON.parse(storedValue)?.[lang] || null : null
    );
  }, [lang]);

  const updateServiceName = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    const storedValue = localStorage.getItem("minor_service_name");
    setServiceName(
      storedValue ? JSON.parse(storedValue)?.[lang] || null : null
    );
  };
  const { data: categories, isLoading: categoriesLoading } = useQuery({
    queryKey: ["services-categories", lang],
    queryFn: () => getApi("/service-categories"),
  });

  useEffect(() => {
    console.log(categories);
  }, [categories]);

  const { data: serviceData, isLoading: serviceDataLoading } = useQuery({
    queryKey: ["service-detail", serviceName, lang],
    queryFn: () => getApi("/services"),
  });

  useEffect(() => {
    const contentContainer = document.querySelector(
      ".service_detail_main_content_element"
    );

    serviceData &&
      serviceData?.map((data) => {
        if (data.title?.[lang] === serviceName) {
          if (contentContainer) {
            contentContainer.innerHTML = "";
          }
          const content = document.createElement("p");
          content.classList.add("detail_content");
          content.innerHTML = data.content?.[lang];
          contentContainer.appendChild(content);
        }
      });
  }, [serviceData, serviceName, lang]);
  return (
    <div className="service_detail">
      <div className="service_detail_head">
        <h3>{serviceName}</h3>
      </div>
      <div className="service_detail_main">
        <div className="service_detail_main_content">
          {serviceDataLoading ? (
            <Spinner />
          ) : (
            <div className="service_detail_main_content_element"></div>
          )}
        </div>
        <div className="service_detail_main_right">
          <div className="service_detail_main_right_categories">
            <h3 className="service_detail_main_right_categories_head">
              {t("services.categories")}
            </h3>
            <div className="service_detail_main_right_categories_container">
              {categoriesLoading && <Spinner />}
              {categories &&
                categories.map((category) => (
                  <ServiceCategory
                    name={category.title[lang]}
                    services={category.services}
                    updateService={updateServiceName}
                    activeService={serviceName}
                  />
                ))}
            </div>
          </div>
          <button
            onClick={() => navigate("/contact")}
            className="service_detail_contact_button"
          >
            {t("services.contactBtn")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ServiceDetail;
