import React, { useContext, useEffect, useState } from "react";
import "./detail.scss";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getApi } from "../../../api/customApi";
import Spinner from "../../../components/Spinner";
import { LangContext } from "../../../context/LangContext";

const CertificateDetail = () => {
  const navigate = useNavigate();
  const { lang } = useContext(LangContext);
  const { t } = useTranslation();

  const [selectedCertificate, setSelectedCertificate] = useState(null);

  const { data: certificatesData, isLoading: certificatesIsLoading } = useQuery(
    {
      queryKey: ["certificates", lang],
      queryFn: () => getApi("/certificates"),
    }
  );

  useEffect(() => {
    const storedCertificate = JSON.parse(
      localStorage.getItem("minor_certificate")
    );
    if (storedCertificate) {
      setSelectedCertificate(storedCertificate);
    }
  }, []);

  const handleCertificateClick = (certificate) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
    const certificateData = {
      content: certificate.content?.[lang],
      name: certificate.title?.[lang],
      description: certificate.description?.[lang],
    };
    setSelectedCertificate(certificateData);
    localStorage.setItem("minor_certificate", JSON.stringify(certificateData));
  };

  return (
    <div className="certificate_detail">
      <div className="certificate_detail_head">
        {selectedCertificate && (
          <>
            <h1>{selectedCertificate.name}</h1>
            <h3>{selectedCertificate.description}</h3>
          </>
        )}
      </div>
      <div className="certificate_detail_main">
        <div className="certificate_detail_main_content">
          {selectedCertificate && (
            <p
              dangerouslySetInnerHTML={{ __html: selectedCertificate.content }}
            />
          )}
        </div>
        <div className="certificate_detail_main_right">
          <div className="certificate_detail_main_right_certificates">
            <h3 className="certificate_detail_main_right_certificates_head">
              {t("certificates.other")}
            </h3>
            <div className="certificate_detail_main_right_certificates_container">
              {certificatesData &&
                certificatesData.map((data, index) => {
                  if (data?.title?.[lang] !== selectedCertificate?.name) {
                    return (
                      <Link
                        key={data.slug?.[lang]}
                        to={`/certification/${data.slug?.[lang]}`}
                        className="certificates_container_link"
                        onClick={() => handleCertificateClick(data)}
                      >
                        <div className="certificate_information">
                          <h3 className="certificate_name">
                            <span className="certificate_code">
                              {data.title?.[lang]}
                            </span>
                          </h3>
                          <p>{data.description?.[lang]}</p>
                        </div>
                        <div className="grey_line"></div>
                      </Link>
                    );
                  }
                })}
              {certificatesIsLoading && <Spinner />}
            </div>
          </div>
          <button
            onClick={() => navigate("/contact")}
            className="certificate_detail_contact_button"
          >
            {t("services.contactBtn")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CertificateDetail;
