import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { enTranslation } from "./translate/en/translation.js";
import { azTranslation } from "./translate/az/translation.js";
import { ruTranslation } from "./translate/ru/translation.js";

const storedLanguage = localStorage.getItem('language') || 'az';

i18n.use(initReactI18next).init({
  resources: {
    en: enTranslation,
    az: azTranslation,
    ru: ruTranslation,
  },
  lng: storedLanguage,
  fallbackLng: "az",
});

export default i18n;
