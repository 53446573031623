import React, { useContext } from "react";
import Spinner from "../../components/Spinner";
import "../Contact/contact.scss";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { getApi } from "../../api/customApi";
import { LangContext } from "../../context/LangContext";

const Contact = () => {
  const { t } = useTranslation();
  const { lang } = useContext(LangContext);

  const { data: configuration, isLoading } = useQuery({
    queryKey: ["configuration", lang],
    queryFn: () => getApi("/configuration"),
  });
  return (
    <div className="contact-wrapper">
      <div className="contact-main-image"></div>
      <div className="contact-content">
        <div className="container">
          <div className="main-p">
            <p>{t("contact.info.title")}</p>
          </div>
          {isLoading ? (
            <div className="loading-snipper">
              <Spinner />
            </div>
          ) : (
            <div className="contact-details">
              <div className="contact-item">
                <span>{t("contact.info.addressName")}:</span>
                <p>
                  {configuration?.address?.[lang] || t("contact.info.address")}
                </p>
              </div>
              <div className="contact-item">
                <span>{t("contact.info.mailName")}:</span>
                <NavLink to="mailto:info@minorgroup.az">
                  {configuration?.email || "info@minorgroup.az"}
                </NavLink>
              </div>
              <div className="contact-item">
                <span>{t("contact.info.phoneName")}: </span>
                <NavLink
                  to={`tel:${configuration?.tel}`}
                  className="contact-phone"
                >
                  {configuration?.tel || "(+994) 70 255 09 20"}
                </NavLink>
              </div>
            </div>
          )}
          <div className="contact-button">
            <NavLink to="/contact-form">
              <button>{t("contact.info.btn")}</button>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
