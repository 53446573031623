import React, { useContext } from "react";
import logo from "../../assets/images/minor-group-logo.png";
import "../About/about.scss";
import { useQuery } from '@tanstack/react-query';
import { getApi } from "../../api/customApi";
import { LangContext } from "../../context/LangContext";
import Spinner from "../../components/Spinner";

const Prologue = () => {
  const { lang } = useContext(LangContext);

  const { data: about, isLoading: aboutLoading } = useQuery({
    queryKey: ["about", lang],
    queryFn: () => getApi("/about"),
  });

  return (
    <div className="about-section">
      <div className="bg-header"></div>
      <div className="about">
        <div className="container pro">
          <img
            className="logo mb-hidden"
            src={logo}
            alt="logo"
            loading="lazy"
          />
          <div className="content">
            <div className="center-logo">
              <img
                className="logo mb-block"
                src={logo}
                alt="logo"
                loading="lazy"
              />
            </div>
            <div className="about-content">
            {aboutLoading ? (
                <Spinner />
              ) : (
                about?.prologue?.[lang] || ""
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Prologue